<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  watch: {
    $route(route) {
      console.log(route);
      let routes = route.path;
      this.checkMobile(routes);
    },
  },
  mounted() {},
  methods: {
    checkMobile(url) {
      const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
      if (isMobile) {
        if (url == "/index"||url == "/about"||url == "/contact") {
          this.$router.replace({
            path: "/h5" + url,
          });
        }else if(url.indexOf('/doc/')!=-1){
          let id=url.substring(5)
       
          this.$router.replace({
            path: "/h5/document/" + id,
          });
        }
      } else {
        if (url == "/h5/index"||url == "/h5/about"||url == "/h5/contact") {
          let urls=url.substring(4)
          console.log(urls)
          this.$router.replace({
            path: "/" + urls,
          });
        }else if(url.indexOf('/h5/document/')!=-1){
          let id=url.substring(13)
          console.log(id)
          this.$router.replace({
            path: "/doc/" + id,
          });
        }else if(url.indexOf('/h5/documentList')!=-1){
        
          this.$router.replace({
            path: "/doc/0",
          });
        }
      }
    },
  },
};
</script>

<style>
</style>
