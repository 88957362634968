<template>
  <div class="confirmTip" v-show="status">
    <div class="confirmView">
      <p class="title">
        <span>提示</span>
        <img src="@/assets/images/close_gray.png" @click="close" />
      </p>
      <p class="content">{{content}}</p>
      <div class="confirmBtn flex_g vertical flex_endX">
        <p @click="close" v-if="tipType==1">取消</p>
        <p @click="config" v-if="tipType==1">确定</p>
        <p @click="close" v-if="tipType==2">关闭</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "confirmTip",
  data() {
    return {
      status: false, //是否展示
      tipType: 1, //1确认操作2操作提示
      content: "确认要删除么？",
      contentId: "",
    };
  },
  methods: {
    open(content, id, type) {
      this.status = true;
      if (content != null && content != undefined) {
        this.content = content;
      }
      if (id != null && id != undefined) {
        this.contentId = id;
      }
      if (type != null && type != undefined) {
        this.tipType = type;
      }
    },
    close() {
      this.status = false;
      this.$emit("cancle");
    },
    config() {
      this.status = false;
      this.$emit("config", this.contentId);
    },
  },
};
</script>

<style lang="scss" scoped>
.confirmTip {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000000;
  .confirmView {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    width: 380px;
    border-radius: 10px;
    .title {
      padding: 20px 2rem;
      position: relative;
      border-bottom: 1px solid #e5e5e5;
      text-align: left;
      span {
        font-size: 1.3rem;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #39475b;
      }
      img {
        width: 13px;
        height: 14px;
        position: absolute;
        top: 12px;
        right: 12px;
      }
    }
    .content {
      font-size: 1.1rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #39475b;
      padding:3rem 2.5rem 2rem;
    }

    .confirmBtn {
      margin-bottom: 2.5rem;
      margin-right: 2rem;
      p {
       width: 8.08rem;
        padding: 0.8rem 0;
        border-radius: 5px;
        font-size: 1rem;
        margin: 0 0.5rem;
        text-align: center;
        cursor: pointer;
      }
      p:nth-child(1) {
        color: #999;
        border: 1px solid #D9D9D9;
      }
      p:nth-child(2) {
        color: #fff;
        background: #336FF1;
      }
    }
  }
}
</style>