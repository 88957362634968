import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/base.css'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import loading from './components/loading';
import confirmTip from './components/confirmTip';
import {get, post } from './http/http';
import api from './http/api';
import getDate from './utils/getDate';
import mavonEditor from 'mavon-editor'
import 'mavon-editor/dist/css/index.css'
// use
Vue.use(mavonEditor)


Vue.use(api);
Vue.use(loading); //加载动画
Vue.use(confirmTip);//确认操作提示
Vue.prototype.$get = get;
Vue.prototype.$post = post;
Vue.config.productionTip = false
Vue.prototype.$getDate = getDate;

Vue.use(ElementUI);
router.afterEach((to, from, next) => {
  window.scrollTo(0, 0);
  if (to.meta.title) {
    document.title = to.meta.title
  }
})
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
