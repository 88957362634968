import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)
//避免重复导航
// const originalPush = VueRouter.prototype.push
// VueRouter.prototype.push = function push(location) {
//   return originalPush.call(this, location).catch(err => err)
// }
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
const originalReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch(err => err)
}
const routes = [
  {
    path: '*',
    redirect: '/'
  },
  {
    path: '/',
    redirect: '/index'
  },
  // {
  //   path: '/index',
  //   name: 'index',
  //   component: () =>
  //     import('../pages/index.vue')
  // }, 
  {
    path: '/softIndex',
    name: 'softIndex',
    component: () => import('../pages/softIndex.vue')
  }, {
    path: '/softProduct',
    name: 'softProduct',
    component: () => import('../pages/softProduct.vue')
  }, {
    path: '/index',
    name: 'index',
    meta:{
      title: '哈雅科技-企业级云服务提供商',
    },
    component: () => import('../pages/pcIndex.vue')
  }, {
    path: '/about',
    name: 'about',
    meta:{
      title: '关于我们',
    },
    component: () => import('../pages/aboutIndex.vue')
  }, {
    path: '/help',
    name: 'help',
    component: () =>
      import('../pages/help.vue')
  }, {
    path: '/help/SearchResult/:searchKey',
    name: 'helpSearchResult',
    component: () =>
      import('../pages/helpSearchResult.vue')
  }, {
    path: '/help/helpList/:categoryId',
    name: 'helpList',
    component: () =>
      import('../pages/helpList.vue')
  }, {
    path: '/help/helpDetail/:id',
    name: 'helpDetail',
    component: () =>
      import('../pages/helpDetail.vue')
  },
  {
    path: '/solution/education',
    name: 'education',
    meta:{
      title: '在线教育解决方案',
    },
    component: () =>
      import('../pages/solution/education.vue')
  }, {
    path: '/solution/health',
    name: 'healthIndex',
    meta:{
      title: '智慧医疗解决方案',
    },
    component: () => import('../pages/solution/healthIndex.vue')
  }, {
    path: '/solution/iot',
    name: 'iotIndex',
    component: () =>
      import('../pages/solution/iotIndex.vue')
  }, {
    path: '/solution/country',
    name: 'country',
    meta:{
      title: '数字乡村解决方案',
    },
    component: () =>
      import('../pages/solution/country.vue')
  }, {
    path: '/solution/mall',
    name: 'mall',
    meta:{
      title: '新零售解决方案',
    },
    component: () =>
      import('../pages/solution/mall.vue')
  },
  {
    path: '/solution/Aiot',
    name: 'AiotIndex',
    meta:{
      title: 'AIOT解决方案',
    },
    component: () =>
      import('../pages/solution/AiotIndex.vue')
  },
  {
    path: '/h5/contact',
    name: 'contact_h5',
    component: () =>
      import('../pages/apply_h5.vue')
  },{
    path: '/h5/contactResult',
    name: 'contactResult',
    component: () =>
      import('../pages/applyResult.vue')
  },
  {
    path: '/contact',
    name: 'contact',
    meta:{
      title: '申请体验',
    },
    component: () =>
      import('../pages/contact.vue')
  },
  {
    path: '/doc/:documentId',
    name: 'document',
    meta:{
      title: '文档中心',
    },
    component: () =>
      import('../pages/document.vue'),
    children: [
      // {
      //   path: ':documentId',
      //   name: 'content',
      //   component: () =>
      //     import('../pages/documentInfo.vue'),
      // }
    ]
  },
  // {
  //     path: '/help/helpCategoryH5',
  //     name: 'helpCategoryH5',
  //     component: () =>
  //         import ('./pages/helpCategoryH5.vue')
  // },

  {
    path: '/h5/index',
    name: 'index_h5',
    meta:{
      title: '哈雅科技-企业级云服务提供商',
    },
    component: () => import('../pages/h5/index.vue')
  }, {
    path: '/h5/about',
    name: 'about_h5',
    meta:{
      title: '关于我们',
    },
    component: () => import('../pages/h5/about.vue')
  }, 
  {
    path: '/h5/documentList',
    name: 'documentList_h5',
    meta:{
      title: '帮助文档',
    },
    component: () => import('../pages/h5/documentList.vue')
  }, 
  {
    path: '/h5/document/:documentId',
    name: 'document_h5',
    meta:{
      title: '帮助文档',
    },
    component: () => import('../pages/h5/document.vue')
  }, 
]

const router = new VueRouter({
  mode:'history',
  routes
})

export default router
